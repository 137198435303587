<template>
  <div class="card card-custom card-stretch gutter-b d-flex">
    <div class="form_forgot-password">
      <el-container>
        <el-main>
          <div class="pl-20 pr-20 mt-3">
            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
              パスワード再設定
            </h3>
            <el-form :model="ruleForm" status-icon ref="ruleForm" label-position="top"
                     class="demo-ruleForm demo-form">
              <el-form-item prop="password">
                <label>新しいパスワード <span class="error-message">*</span></label>
                <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item prop="password_confirmation">
                <label>新しいパスワード再入力 <span class="error-message">*</span></label>
                <el-input type="password" v-model="ruleForm.password_confirmation" autocomplete="off"></el-input>
              </el-form-item>
              <br>
            </el-form>
            <el-button class="pl-10 pr-10" type="primary" @click="showPopup">パスワードを再設定する</el-button>
          </div>
        </el-main>
      </el-container>
      <Popup id="PopupConfirm" title="確認" :content="errorMessage" :title_button="close_popup">
        <b-button @click="onSubmit()">{{$t("YES")}}</b-button>
      </Popup>
    </div>
  </div>
</template>

<script>
import apiService from "@/core/services/api.service";
import {RESET_PASSWORD} from "../../../core/services/store/auth.module";
import Popup from "@/view/components/Popup";

export default {
  name: "ResetPassword",
  components: {Popup},

  data() {



    var validatePasswordConfirmation = (rule, value, callback) => {
      if (value !== this.ruleForm.password) {
        return  callback(new Error(this.$t("password_confirmation") ));
      }  else {
        callback();
      }
    };
    return {
      ruleForm: {
        token: '',
        email: '',
        password: '',
        password_confirmation: '',

      },
      routeLogin: { name: "login" },
      errorMessage: "",
      close_popup: this.$t("NO"),
    }
  },
  computed: {
    queryParam() {
      return this.$route.query
    }
  },
  created() {
    this.getDataFromQuery();
  }
  ,

  methods: {
    getDataFromQuery() {
      this.ruleForm.email = this.queryParam.email ? this.queryParam.email : '';
      this.ruleForm.token = this.queryParam.token ? this.queryParam.token : '';
    }
    ,
    onSubmit() {
        if (this.$route.params.tenant_dir){
            this.routeLogin = {name: 'tenant_login', params: { tenant_dir: this.$route.params.tenant_dir }}
        }
        this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.turnOffErrorMessage('ruleForm')
          var token = this.ruleForm.token;
          var email = this.ruleForm.email;
          var password_confirmation = this.ruleForm.password_confirmation;
          var password = this.ruleForm.password;
          this.$store.dispatch(RESET_PASSWORD, {token, email, password, password_confirmation})
              .then(data => {
                if (!data.success)  {
                  let object = data.data;
                  for (const property in object) {
                    for (let i = 0; i < object[property].length; i++) {
                      this.showErrorMessage(object, "ruleForm");
                      break;
                    }
                  }
                  if (this.errorMessage) {
                    this.$bvModal.hide('PopupConfirm')
                  }
                }else{
                  this.notifySuccess(this.$t("change_password_success"))
                  setTimeout(() => {
                      this.$router.push(this.routeLogin)
                  }, 1000);
                }
              }).catch(e => {
                    this.loading = false
                    this.notifyError(e)
          })
        }
      });
    },
    showPopup(){
      this.errorMessage = this.$t('RESET_PASSWORD');
      this.$bvModal.show("PopupConfirm");
    }
  }
}
</script>

<style scoped>
.form_forgot-password {
  width: 80%;
  margin: 0 auto;
}

</style>
